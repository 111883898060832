/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Dropdown,
  Modal,
  Popover,
  Col,
  Row,
  Badge,
} from 'antd';
import {
  TagOutlined,
  TeamOutlined,
  UserOutlined,
  DownOutlined,
  BellOutlined,
  HomeOutlined,
  BankOutlined,
  AuditOutlined,
  MessageOutlined,
  SnippetsOutlined,
  FileSyncOutlined,
  PoweroffOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  FileExcelOutlined,
  ContainerOutlined,
  ApartmentOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  RedEnvelopeOutlined,
  NotificationOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  UsergroupDeleteOutlined,
  ShopOutlined,
  SettingOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import i18n from '../../i18n';
import store from '../../store';
import routes from './dashboardRoutes';
import Loading from '../basic/Loading';
import getAgentInstance from '../../helpers/superagent';
// import largeLogo from '../. ./assets/images/icons/lucid-bg-logo.svg';
import smalLogo from '../../assets/images/icons/lucid-login-logo.svg';
import mainLogo from '../../assets/images/main-logo.png';

import './dashboard.css';
import WhatsappAuthenticator from '../WhatsappAuthenticator';

const superagent = getAgentInstance();
const {
  Header, Content, Footer, Sider,
} = Layout;
const rootSubmenuKeys = ['/', '2', '6', '4', '7'];
const currency = localStorage.getItem('currency');

@inject(
  'userStore',
  'tokenStore',
  'sectionsStore',
  'notification',
  'clientStore',
)
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      loading: true,
      collapsed: false,
      defualtMenu: 'visitlist',
      UIFlags: {},
      access: JSON.parse(this.props.userStore.value.privilege),
      brandingLogo: '',
      brandingColor: '',
      countRent: 0,
      countSale: 0,
      openKeys: [],
      currencies: [],
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const {
        userStore, tokenStore, sectionsStore, clientStore,
      } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
        clientStore.clear(),
      ]).then(() => {});
    };

    this.warning = () => {
      const scope = this;
      Modal.confirm({
        centered: true,
        title: i18n.t('Logout Of The System?'),
        content: '',
        okText: i18n.t('Yes'),
        cancelText: i18n.t('No'),
        onOk() {
          scope.logout();
        },
        onCancel() {},
      });
    };

    this.fetchCounter = () => {
      superagent.get('/debtpending/countme').end((err, res) => {
        if (!err) {
          const { body } = res;
          // this.setState({
          //   countRent: body.countRent,
          //   countSale: body.countSale,
          // });
          store.notification.PaymentValue = body.countRent;
          store.notification.EndingValue = body.countSale;
        }
      });
    };

    this.fetchBrandingLogo = () => {
      const { userStore } = this.props;
      superagent.get('/design/?field=branding_logo').end((error, response) => {
        if (!error) {
          const design = response.body.value;
          userStore.value.brandingLogo = design;
          this.setState({ brandingLogo: design });
        }
      });
    };

    this.fetchCurrency = () => {
      superagent
        .get('/currency/list?limit=1000&offset=0')
        .end((error, response) => {
          if (!error) {
            const currencyData = response.body;
            this.setState({ currencies: currencyData });
          }
        });
    };

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const {
        userStore, tokenStore, sectionsStore, clientStore,
      } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
        clientStore.clear(),
      ]).then(() => {
        //
      });
    };
  }

  componentDidMount() {
    const { userStore } = this.props;
    if (userStore.value.privilege === undefined) {
      this.setState({ access: {} });
    }
    const scope = this;
    superagent.get('/user/me').end((err, res) => {
      if (!err) {
        const { body } = res;
        userStore.value = body;
      } else if (err && err.status === 401) {
        Modal.error({
          centered: true,
          title: 'Unauthorised!',
          content: 'This account is unauthorised, You need to logout!',
          okText: 'Procced',
          onOk() {
            scope.logout();
          },
        });
      }
      this.setState({ loading: false });

      this.fetchCounter();
    });

    this.fetchBrandingLogo();
    // this.fetchBrandingColor();
    this.fetchCurrency();
  }

  render() {
    const { location, userStore, history } = this.props;
    const {
      collapsed,
      loading,
      access,
      brandingLogo,
      brandingColor,
      countRent,
      countSale,
      openKeys,
      currencies,
    } = this.state;
    const { pathname } = location;
    const { value } = userStore;

    let sectionName;
    if (pathname === '/') {
      sectionName = i18n.t('Dashboard');
    } else if (pathname === '/unit-list') {
      sectionName = i18n.t('Unit');
    } else if (pathname === '/reports') {
      sectionName = i18n.t('Reports');
    } else if (pathname === '/unit-type') {
      sectionName = i18n.t('Unit Type');
    } else if (pathname === '/project') {
      sectionName = i18n.t('Project');
    } else if (pathname === '/customer-list') {
      sectionName = i18n.t('Customer');
    } else if (pathname === '/contact-person-type') {
      sectionName = i18n.t('Contact Person Type');
    } else if (pathname === '/invoice') {
      sectionName = i18n.t('Invoice');
    } else if (pathname === '/voucher') {
      sectionName = i18n.t('Voucher');
    } else if (pathname === '/payment-notification') {
      sectionName = i18n.t('Payment Notification');
    } else if (pathname === '/ending-contract-notification') {
      sectionName = i18n.t('Ending Contract Notification');
    } else if (pathname === '/offer') {
      sectionName = i18n.t('Offer');
    } else if (pathname === '/account-statement') {
      sectionName = i18n.t('Account Statement');
    } else if (pathname === '/sms') {
      sectionName = i18n.t('SMS');
    } else if (pathname === '/sale-contract') {
      sectionName = i18n.t('Sale Contracts');
    } else if (pathname === '/rent-contract') {
      sectionName = i18n.t('Rent Contracts');
    } else if (pathname === '/archive-contracts') {
      sectionName = i18n.t('Archives');
    } else if (pathname === '/my-profile') {
      sectionName = i18n.t('My Profile');
    } else if (pathname === '/user-profile') {
      sectionName = i18n.t('User Profile');
    } else if (pathname === '/safe') {
      sectionName = i18n.t('Safe');
    } else if (pathname === '/print-setting') {
      sectionName = i18n.t('Print Setting');
    }

    const menu = (
      <Menu style={{ top: '10px' }}>
        <Menu.Item
          onClick={() => history.push('/my-profile')}
          icon={(
            <UserOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('My Profile')}
        </Menu.Item>
        {access['View User'] && (
          <Menu.Item
            onClick={() => history.push('/user-profile')}
            icon={(
              <TeamOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('User Profile')}
          </Menu.Item>
        )}
        {access['View Branch'] && (
          <Menu.Item
            onClick={() => history.push('/branches')}
            icon={(
              <TeamOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('Branches')}
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={() => history.push('/print-setting')}
          icon={(
            <SettingOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('Print Setting')}
        </Menu.Item>
        <Menu.Item
          onClick={this.warning}
          icon={(
            <PoweroffOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('Logout')}
        </Menu.Item>
      </Menu>
    );

    const notificationMenu = (
      <Menu style={{ top: '10px', border: 'none', width: '150px' }}>
        {access['View Rent Notifications'] && (
          <Menu.Item onClick={() => history.push('/payment-notification')}>
            <Badge
              count={
                store.notification.PaymentValue !== 0
                  ? store.notification.PaymentValue
                  : ''
              }
              size="small"
              style={{
                zIndex: '99',
                marginRight: '-10px',
                padding: '3px',
                height: '20px',
                borderRadius: '100%',
              }}
              onClick={() => store.notification.clearPayment()}
            >
              {i18n.t('Payment Notification')}
            </Badge>
          </Menu.Item>
        )}
        {access['View Sale Notifications'] && (
          <Menu.Item
            onClick={() => history.push('/ending-contract-notification')}
          >
            <Badge
              count={
                store.notification.EndingValue !== 0
                  ? store.notification.EndingValue
                  : ''
              }
              size="small"
              style={{
                zIndex: '99',
                marginRight: '-10px',
                padding: '3px',
                height: '20px',
                borderRadius: '100%',
              }}
              onClick={() => store.notification.clearEnding()}
            >
              {i18n.t('Ending Contract Notification').substring(0, 15)}
            </Badge>
          </Menu.Item>
        )}
      </Menu>
    );

    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openKeys: keys });
      } else {
        this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
      }
    };

    return (
      <>
        <style>
          {`

          .sider_toggler_btn {
            position: static !important;
          top: 0 !important;
          left: 0px !important;
          z-index: 2;
          }

          .sider_menu_links.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item:hover {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item-selected::before {
              content: '';
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: ${brandingColor};
          }
        `}
        </style>
        <div
          style={{
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: !loading ? 'none' : 'flex',
          }}
        >
          <Loading visible={loading} tip={i18n.t('Loading User Data...')} />
        </div>
        <Layout
          style={{
            minHeight: '100vh',
            display: loading ? 'none' : undefined,
          }}
        >
          <Sider
            theme="light"
            className="layout_sider"
            width={240}
            collapsed={collapsed}
            style={{
              height: '100vh',
              left: i18n.language === 'en' ? 0 : null,
              right: i18n.language !== 'en' ? 0 : null,
              position: 'fixed',
              overflow: 'auto',
            }}
          >
            <div className={collapsed ? 'logo_small' : 'logo'}>
              {collapsed ? (
                <img
                  alt="lucid-logo"
                  style={{ width: 65 }}
                  src={brandingLogo !== '' ? brandingLogo : smalLogo}
                />
              ) : (
                <img
                  alt="lucid-logo"
                  style={{ width: 185 }}
                  src={brandingLogo !== '' ? brandingLogo : 'largeLogo'}
                />
              )}
            </div>
            <Menu
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className="sider_menu_links"
              selectedKeys={[location.pathname]}
            >
              {access['View Dashboard'] && (
                <Menu.Item
                  key="/"
                  onClick={() => this.setState({ openKeys: [] })}
                >
                  <LineChartOutlined />
                  <span>{i18n.t('Dashboard')}</span>
                  <Link to="/" />
                </Menu.Item>
              )}
              <Menu.SubMenu
                key={1}
                title={i18n.t('Website Info')}
                icon={<WindowsOutlined />}
              >
                {access['Edit Website Info'] && (
                  <Menu.Item key="/propertyType">
                    <HomeOutlined />
                    <span>{i18n.t('Property Type')}</span>
                    <Link to="/propertyType" />
                  </Menu.Item>
                )}
                {access['Edit Website Info'] && (
                  <Menu.Item key="/cities">
                    <ShopOutlined />
                    <span>{i18n.t('City')}</span>
                    <Link to="/cities" />
                  </Menu.Item>
                )}
                {access['Edit Website Info'] && (
                  <Menu.Item key="/employees">
                    <UserOutlined />
                    <span>{i18n.t('Employees')}</span>
                    <Link to="/employees" />
                  </Menu.Item>
                )}
              </Menu.SubMenu>
              {(access['View Project']
                || access['View Unit']
                || access['View Offer']) && (
                <Menu.SubMenu
                  key={2}
                  title={i18n.t('Unit Info')}
                  icon={<HomeOutlined />}
                >
                  {access['View Unit'] && (
                    <Menu.Item key="/unit-list">
                      <UnorderedListOutlined />
                      <span>{i18n.t('Unit Definition')}</span>
                      <Link to="/unit-list" />
                    </Menu.Item>
                  )}
                  {access['View Unit'] && (
                    <Menu.Item key="/unit-type">
                      <ApartmentOutlined />
                      <span>{i18n.t('Unit Type')}</span>
                      <Link to="/unit-type" />
                    </Menu.Item>
                  )}
                  {access['View Project'] && (
                    <Menu.Item key="/project">
                      <ContainerOutlined />
                      <span>{i18n.t('Projects')}</span>
                      <Link to="/project" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access['View Contract']
                || access['View Archive Contracts']) && (
                <Menu.SubMenu
                  key={6}
                  title={i18n.t('Contracts')}
                  icon={<SnippetsOutlined />}
                >
                  {access['View Contract'] && (
                    <Menu.Item key="/sale-contract">
                      <TagOutlined />
                      <span>{i18n.t('Sale')}</span>
                      <Link to="/sale-contract" />
                    </Menu.Item>
                  )}
                  {access['View Contract'] && (
                    <Menu.Item key="/rent-contract">
                      <FileSyncOutlined />
                      <span>{i18n.t('Rent Contracts')}</span>
                      <Link to="/rent-contract" />
                    </Menu.Item>
                  )}
                  {access['View Archive Contracts'] && (
                    <Menu.Item key="/archive-contracts">
                      <AuditOutlined />
                      <span>{i18n.t('Archive Contracts')}</span>
                      <Link to="/archive-contracts" />
                    </Menu.Item>
                  )}
                  {access['View Archive Contracts'] && (
                    <Menu.Item key="/cancel-contracts">
                      <AuditOutlined />
                      <span>{i18n.t('Cancel Contracts')}</span>
                      <Link to="/cancel-contracts" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access['View Invoice']
                || access['View Receipt']
                || access['View Expense']
                || access['View Account Statement']) && (
                <Menu.SubMenu
                  key={4}
                  title={i18n.t('Accounting')}
                  icon={<ReconciliationOutlined />}
                >
                  {access['View Receipt'] && (
                    <Menu.Item key="/voucher">
                      <RedEnvelopeOutlined />
                      <span>{i18n.t('Voucher')}</span>
                      <Link to="/voucher" />
                    </Menu.Item>
                  )}
                  {access['View Safe'] && (
                    <Menu.Item key="/safe">
                      <BankOutlined />
                      <span>{i18n.t('Safe')}</span>
                      <Link to="/safe" />
                    </Menu.Item>
                  )}
                  {access['View Receipt'] && (
                    <Menu.Item key="/receipt-draft">
                      <FileExcelOutlined />
                      <span>{i18n.t('Voucher Draft')}</span>
                      <Link to="/receipt-draft" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {access['View Contact'] && (
                <Menu.Item
                  key="/customer-list"
                  onClick={() => this.setState({ openKeys: [] })}
                >
                  <UsergroupDeleteOutlined />
                  <span>{i18n.t('Contacts')}</span>
                  <Link to="/customer-list" />
                </Menu.Item>
              )}

              {(access['View Rent Notifications']
                || access['View Sale Notifications']) && (
                <Menu.SubMenu
                  key={7}
                  title={(
                    <Badge
                      count={
                        store.notification.PaymentValue
                          + store.notification.EndingValue
                        !== 0
                          ? store.notification.PaymentValue
                            + store.notification.EndingValue
                          : ''
                      }
                      size="small"
                      style={{
                        zIndex: '99',
                        marginRight: '-10px',
                        padding: '3px',
                        height: '20px',
                        borderRadius: '100%',
                      }}
                      onClick={() => store.notification.clearEnding()}
                    >
                      {i18n.t('Notifications')}
                    </Badge>
                  )}
                  icon={<NotificationOutlined />}
                  count={
                    store.notification.PaymentValue
                      + store.notification.EndingValue
                    !== 0
                      ? store.notification.PaymentValue
                        + store.notification.EndingValue
                      : ''
                  }
                >
                  {access['View Rent Notifications'] && (
                    <Menu.Item
                      key="/payment-notification"
                      onClick={() => store.notification.clearPayment()}
                    >
                      <BellOutlined />
                      <Badge
                        count={
                          store.notification.PaymentValue !== 0
                            ? store.notification.PaymentValue
                            : ''
                        }
                        size="small"
                        style={{
                          zIndex: '99',
                          marginRight: '-10px',
                          padding: '3px',
                          height: '20px',
                          borderRadius: '100%',
                        }}
                      >
                        <span>{i18n.t('Payment Notification')}</span>
                      </Badge>
                      <Link to="/payment-notification" />
                    </Menu.Item>
                  )}
                  {access['View Sale Notifications'] && (
                    <Menu.Item
                      key="/ending-contract-notification"
                      onClick={() => store.notification.clearEnding()}
                    >
                      <BellOutlined />
                      <Badge
                        count={
                          store.notification.EndingValue !== 0
                            ? store.notification.EndingValue
                            : ''
                        }
                        size="small"
                        style={{
                          zIndex: '99',
                          marginRight: '-10px',
                          padding: '3px',
                          height: '20px',
                          borderRadius: '100%',
                        }}
                      >
                        <span>
                          {i18n
                            .t('Ending Contract Notification')
                            .substring(0, 15)}
                        </span>
                      </Badge>
                      <Link to="/ending-contract-notification" />
                    </Menu.Item>
                  )}
                  {access['View Sale Notifications'] && (
                    <Menu.Item>
                      <BellOutlined />
                      <span>{i18n.t('Archive Notifications')}</span>
                      <Link to="/archive-notification" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {access['View Reports'] && (
                <Menu.Item
                  key="/reports"
                  onClick={() => this.setState({ openKeys: [] })}
                >
                  <PieChartOutlined />
                  <span>{i18n.t('Reports')}</span>
                  <Link to="/reports" />
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          {i18n.language === 'en' ? (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginLeft: collapsed === false ? 240 : 80,
                marginRight: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col>
                  <div className="sider_toggler_btn">
                    <Button
                      type="link"
                      icon={
                        collapsed ? (
                          <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                        ) : (
                          <MenuFoldOutlined style={{ color: '#1f2532' }} />
                        )
                      }
                      onClick={this.toggleSidebar}
                      style={{
                        textAlign: 'start',
                        paddingLeft: 10,
                        paddingRight: undefined,
                      }}
                    />
                  </div>
                </Col>
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 10]}>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                                style={{ fontFamily: 'arabic_regular' }}
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ba');
                                  window.location.reload();
                                }}
                                key="3"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-بادینی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                                style={{ fontFamily: 'CuprumRegular' }}
                              >
                                English
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {' '}
                            {i18n.language === 'sr' ? 'کوردی-سۆرانی' : null}
                            {i18n.language === 'ba' ? 'کوردی-بادینی' : null}
                            {i18n.language === 'en' ? 'English' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>
                        {access['View SMS'] && (
                          <Button
                            title={i18n.t('SMS')}
                            shape="circle"
                            icon={<MessageOutlined />}
                            onClick={() => history.push('/sms')}
                            style={{
                              backgroundColor: '#EAECF2',
                              border: 'none',
                            }}
                          />
                        )}
                      </Col>
                      <Col>
                        <WhatsappAuthenticator />
                      </Col>
                      <Col>
                        {(access['View Rent Notifications']
                          || access['View Sale Notifications']) && (
                          <Popover
                            placement="bottom"
                            content={notificationMenu}
                            trigger="click"
                          >
                            <Badge
                              count={
                                store.notification.PaymentValue
                                  + store.notification.EndingValue
                                !== 0
                                  ? store.notification.PaymentValue
                                    + store.notification.EndingValue
                                  : ''
                              }
                              style={{ zIndex: '99', marginRight: '10px' }}
                            >
                              <Avatar
                                style={{
                                  background: '#EAECF2',
                                  cursor: 'pointer',
                                  marginRight: '10px',
                                }}
                                icon={
                                  <BellOutlined style={{ color: '#000000' }} />
                                }
                              />
                            </Badge>
                          </Popover>
                        )}
                      </Col>

                      <Col>
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginLeft: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>
              <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center', direction: 'ltr' }}>
                <span>Powered By</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://alaqarat.com"
                  title="Lucid Source"
                >
                  <img src={mainLogo} alt="logo" height="30px" />
                </a>
              </Footer>
            </Layout>
          ) : (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginRight: collapsed === false ? 240 : 80,
                marginLeft: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col
                  span={1}
                  style={{
                    textAlign: 'start',
                    paddingLeft: undefined,
                    paddingRight: 10,
                  }}
                >
                  <Button
                    type="link"
                    icon={
                      collapsed ? (
                        <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                      ) : (
                        <MenuFoldOutlined style={{ color: '#1f2532' }} />
                      )
                    }
                    onClick={this.toggleSidebar}
                  />
                </Col>
                {/* <div className="sider_toggler_btn">
                    <Button
                      type="link"
                      icon={
                        collapsed ? <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                          : <MenuFoldOutlined style={{ color: '#1f2532' }} />
                      }
                      onClick={this.toggleSidebar}
                    />
                  </div> */}
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 10]}>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                                style={{ fontFamily: 'arabic_regular' }}
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ba');
                                  window.location.reload();
                                }}
                                key="3"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-بادینی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                                style={{ fontFamily: 'CuprumRegular' }}
                              >
                                English
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {' '}
                            {i18n.language === 'sr' ? 'کوردی-سۆرانی' : null}
                            {i18n.language === 'ba' ? 'کوردی-بادینی' : null}
                            {i18n.language === 'en' ? 'English' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              {currencies.map((crn) => (
                                <Menu.Item
                                  onClick={() => {
                                    localStorage.setItem('currency', crn.id);
                                    window.location.reload();
                                  }}
                                  key={crn.id}
                                  style={{
                                    fontFamily: 'arabic_regular',
                                    textAlign: 'right',
                                  }}
                                  disabled
                                >
                                  {crn.symbol}
                                </Menu.Item>
                              ))}
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {currency === null && '$'}
                            {currency === '2' && '$'}
                            {currency === '1' && 'د.ع'}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>
                        {access['View SMS'] && (
                          <Button
                            title={i18n.t('SMS')}
                            shape="circle"
                            icon={<MessageOutlined />}
                            onClick={() => history.push('/sms')}
                            style={{
                              backgroundColor: '#EAECF2',
                              border: 'none',
                            }}
                          />
                        )}
                      </Col>

                      <Col>
                        {(access['View Rent Notifications']
                          || access['View Sale Notifications']) && (
                          <Popover
                            placement="bottom"
                            content={notificationMenu}
                            trigger="click"
                          >
                            <Badge
                              count={countSale + countRent}
                              style={{ zIndex: '99', marginLeft: '10px' }}
                            >
                              <Avatar
                                style={{
                                  background: '#EAECF2',
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                }}
                                icon={
                                  <BellOutlined style={{ color: '#000000' }} />
                                }
                              />
                            </Badge>
                          </Popover>
                        )}
                      </Col>

                      <Col>
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col style={{ marginLeft: '10px' }}>
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginRight: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>
              <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center', direction: 'ltr' }}>
                <span>Powered By</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://alaqarat.com"
                  title="Lucid Source"
                >
                  <img src={mainLogo} alt="logo" height="30px" />
                </a>
              </Footer>
            </Layout>
          )}
        </Layout>
      </>
    );
  }
}

export default withTranslation()(
  withRouter((props) => <Dashboard {...props} />),
);
